export const getNormalizedPlannerScreen = ({ screenData }) => {
  return screenData?.flat(1)?.map((screen, index) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [screen?.attributes?.long, screen?.attributes?.lat],
      },
      properties: {
        screenType: index % 2 === 0 ? 'in' : 'out',
      },
    };
  });
};

export const getNormalizeCountryMap = (countryList) => {
  return countryList.reduce((acc, current) => {
    if (acc[current.regions.data[0].id]) {
      return {
        ...acc,
        [current.regions.data[0].id]: [
          ...acc[current.regions.data[0].id],
          current,
        ],
        5: [...acc[5], current],
      };
    }

    return {
      ...acc,
      [current.regions.data[0].id]: [current],
      5: [current],
    };
  }, {});
};
