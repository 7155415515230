export default {
  FETCH_PLANNER_SCREENS: 'FETCH_PLANNER_SCREENS',
  FETCH_PLANNER_SCREENS_SUCCESS: 'FETCH_PLANNER_SCREENS_SUCCESS',
  FETCH_LOCATION_LIST: 'FETCH_LOCATION_LIST',
  FETCH_LOCATION_LIST_SUCCESS: 'FETCH_LOCATION_LIST_SUCCESS',
  FETCH_COUNTRY_LIST: 'FETCH_COUNTRY_LIST',
  FETCH_COUNTRY_LIST_SUCCESS: 'FETCH_COUNTRY_LIST_SUCCESS',
  FETCH_CITY_LIST: 'FETCH_CITY_LIST',
  FETCH_CITY_LIST_SUCCESS: 'FETCH_CITY_LIST_SUCCESS',
  FETCH_STREET_LIST: 'FETCH_STREET_LIST',
  FETCH_STREET_LIST_SUCCESS: 'FETCH_STREET_LIST_SUCCESS',
  UPDATE_LOCATION_LIST: 'UPDATE_LOCATION_LIST',
  APPLY_GEO_FILTER_SETTINGS: 'APPLY_GEO_FILTER_SETTINGS',
  APPLY_GEO_FILTER_SETTINGS_SUCCESS: 'APPLY_GEO_FILTER_SETTINGS_SUCCESS',
  UPDATE_CITY_LIST: 'UPDATE_CITY_LIST',
  UPDATE_GEOFILTER_OVERLAY_STATE: 'UPDATE_GEOFILTER_OVERLAY_STATE',
};
