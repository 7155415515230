import { getNormalizedPlannerScreen } from '@apps/PlannerScreen/utils';
import { cities } from '@constants/index';
import { PlannerActions } from '@store/actions';
import { sagaAPIMethods } from '@utils/requestHandler';
import { call, fork, put, take } from 'redux-saga/effects';

function* fetchPlannerScreensSaga() {
  try {
    while (true) {
      const data = yield take(PlannerActions.FETCH_PLANNER_SCREENS);

      const { setLoading, onError, onSuccess, payload } = data.payload;

      const url = `/api/v1/:instance_code/dooh_screen`;

      setLoading?.(true);

      const response = yield call(sagaAPIMethods.post, url, payload);

      if (response?.status >= 400 || response?.data?.error) {
        onError?.(response.data.error);
        setLoading?.(false);
      } else {
        yield put({
          type: PlannerActions.FETCH_PLANNER_SCREENS_SUCCESS,
          payload: response?.data?.data,
        });

        onSuccess?.(
          getNormalizedPlannerScreen({ screenData: response?.data?.data })
        );
        setLoading?.(false);
      }
    }
  } catch (errors) {
    // eslint-disable-next-line
    console.log('fetchPlannerScreensSaga', errors);
  }
}

function* fetchLocationListSaga() {
  try {
    while (true) {
      const data = yield take(PlannerActions.FETCH_LOCATION_LIST);

      const { setLoading, onError, onSuccess } = data.payload || {};

      const url = `/api/v1/:instance_code/dooh_screen_coordinates`;

      setLoading?.(true);

      const response = yield call(sagaAPIMethods.get, url);

      if (response?.status >= 400 || response?.data?.error) {
        onError?.(response.data.error);
        setLoading?.(false);
      } else {
        yield put({
          type: PlannerActions.FETCH_LOCATION_LIST_SUCCESS,
          payload: response?.data?.data,
        });

        onSuccess?.();
        setLoading?.(false);
      }
    }
  } catch (errors) {
    // eslint-disable-next-line
    console.log('fetchLocationListSaga', errors);
  }
}

function* fetchCountryListSaga() {
  try {
    while (true) {
      const data = yield take(PlannerActions.FETCH_COUNTRY_LIST);

      const { setLoading, onError, onSuccess } = data.payload || {};

      const url = `https://doohplannerapi.dev161.com/api/v1/:instance_code/countries`;

      setLoading?.(true);

      const response = yield call(sagaAPIMethods.get, url);

      if (response?.status >= 400 || response?.data?.error) {
        onError?.(response.data.error);
        setLoading?.(false);
      } else {
        yield put({
          type: PlannerActions.FETCH_COUNTRY_LIST_SUCCESS,
          payload: response?.data?.data,
        });

        onSuccess?.();
        setLoading?.(false);
      }
    }
  } catch (errors) {
    // eslint-disable-next-line
    console.log('fetchCountryListSaga', errors);
  }
}

function* fetchCityListSaga() {
  try {
    while (true) {
      const data = yield take(PlannerActions.FETCH_CITY_LIST);

      const { setLoading, onError, onSuccess, countryIds } = data.payload || {};

      const url = `https://doohplannerapi.dev161.com/api/v1/:instance_code/countries/${countryIds}`;
      setLoading?.(true);
      const response = yield call(sagaAPIMethods.get, url);
      if (response?.status >= 400 || response?.data?.error) {
        onError?.(response.data.error);
        setLoading?.(false);
      } else {
        yield put({
          type: PlannerActions.FETCH_CITY_LIST_SUCCESS,
          payload: response?.data?.data,
        });

        onSuccess?.(response?.data?.data);
        setLoading?.(false);
      }
    }
  } catch (errors) {
    // eslint-disable-next-line
    console.log('fetchCityListSaga', errors);
  }
}

function* applyGeoFilterSettingsSaga() {
  try {
    while (true) {
      const data = yield take(PlannerActions.APPLY_GEO_FILTER_SETTINGS);

      const { setLoading, onError, onSuccess, payload } = data.payload;

      const url = `/api/v1/:instance_code/dooh_screen`;

      setLoading?.(true);

      const response = yield call(sagaAPIMethods.post, url, payload);

      if (response?.status >= 400 || response?.data?.error) {
        onError?.(response.data.error);
        setLoading?.(false);
      } else {
        yield put({
          type: PlannerActions.FETCH_PLANNER_SCREENS_SUCCESS,
          payload: response?.data?.data,
        });

        onSuccess?.(
          getNormalizedPlannerScreen({ screenData: response?.data?.data })
        );
        setLoading?.(false);
      }
    }
  } catch (errors) {
    // eslint-disable-next-line
    console.log('fetchPlannerScreensSaga', errors);
  }
}

export default function* () {
  yield fork(fetchPlannerScreensSaga);
  yield fork(fetchLocationListSaga);
  yield fork(fetchCountryListSaga);
  yield fork(fetchCityListSaga);
  yield fork(applyGeoFilterSettingsSaga);
}
