import { Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import SearchIcon from '../../../Images/Icons/SearchIcon';
import Text from '@components/Text';
import Heading from '@components/Heading';
import Button from '@components/Button/Button';
import CrossIcon from '../../../Images/Icons/CrossIcon';
import { connect } from 'react-redux';
import {
  getCityListSelector,
  getcountryRegionMapSelector,
  getGeoFilterStateSelector,
} from '@store/selectors';
import { AutoSizer, List } from 'react-virtualized';
import { actionHandler, PlannerActions } from '@store/actions';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { countryList } from '@constants/index';
import { isEmpty, isEqual } from 'lodash';

const placeholderMap = {
  country: 'Search Countries..',
  city: 'Search Cities',
  street: 'Search Streets',
};

function TabContent({
  initialValue = [],
  initialFilterValue = {},
  onChange,
  filteredList,
  setFilteredList,
  isLoading,
  dispatchAction,
  overlayState,
  type = 1,
  name = '',
}) {
  const [data, setData] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const isCountryTab = name === 'country';

  useEffect(() => {
    if (data.length !== initialValue.length) {
      const initalSelectedValues = initialValue.map((item) => {
        if (initialFilterValue[item.id]) {
          return { ...item, selected: true };
        }

        return item;
      });

      setData(initalSelectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (!isEqual(initialFilterValue, filteredList)) {
      setFilteredList(initialFilterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderRow({ index, key, style }) {
    const item = data[index];
    return (
      <Text
        key={key}
        style={{ ...style, color: '#535353' }}
        selectable
        selected={data[index]?.selected || false}
        onClick={() => {
          const currentId = data[index]?.id;
          const currentObject = { [currentId]: data[index] };
          setData((prev) => {
            return prev?.map((x) => {
              if (x?.id === currentId) {
                return { ...x, selected: x.selected ? false : true };
              }
              return x;
            });
          });

          if (currentObject[currentId].selected) {
            toggleSelectedOption(currentId);
          } else {
            setFilteredList((prev) => {
              return {
                ...prev,
                ...currentObject,
              };
            });
            onChange({ ...filteredList, ...currentObject });
            const payload = isCountryTab
              ? {
                  selectedCountries: {
                    ...filteredList,
                    ...currentObject,
                  },
                }
              : name === 'city'
                ? {
                    selectedCity: {
                      ...filteredList,
                      ...currentObject,
                    },
                  }
                : {
                    selectedLocation: {
                      ...filteredList,
                      ...currentObject,
                    },
                  };

            dispatchAction(PlannerActions.UPDATE_GEOFILTER_OVERLAY_STATE, {
              ...payload,
            });
          }
        }}
      >
        {item?.attributes?.name || item?.name || item?.street}
      </Text>
    );
  }

  const handleSearch = (searchKeyword) => {
    if (searchKeyword.length) {
      const filteredList = data.filter((item) => {
        const value = item?.attributes?.name || item?.name || item?.street;
        return value
          ?.toLowerCase()
          ?.includes(searchKeyword?.toLocaleLowerCase());
      });

      setData(filteredList);
    } else {
      setData(initialValue);
    }
  };
  const summaryList = Object.entries(filteredList);

  const showBorder = data.length < 7 ? true : false;

  const countryTabContainerStyles = isCountryTab
    ? {
        paddingLeft: '12px',
        paddingTop: '4px',
        borderRight: showBorder ? '1px solid #E7E7E7' : 'unset',
      }
    : {
        paddingBottom: '3px',
        borderRight: showBorder ? '1px solid #E7E7E7' : 'unset',
      };

  function updateOverlayState({ payload }) {
    dispatchAction(PlannerActions.UPDATE_GEOFILTER_OVERLAY_STATE, {
      ...payload,
    });
  }

  function toggleSelectedOption(key) {
    const updatedList = { ...filteredList };
    delete updatedList[key];

    setFilteredList(updatedList);
    onChange(updatedList);

    const overlayStateClone = { ...overlayState };
    if (name === 'street') {
      delete overlayStateClone?.latlang[key];
    }

    const payload = isCountryTab
      ? {
          selectedCountries: updatedList,
        }
      : name === 'city'
        ? {
            selectedCity: updatedList,
          }
        : {
            selectedLocation: overlayStateClone?.latlang,
          };

    updateOverlayState({ payload });
  }

  function resetList() {
    const resetList = data.map((x) => {
      return {
        ...x,
        selected: false,
      };
    });
    setData(resetList);
  }

  return (
    <div className="flex-1">
      <>
        <div
          style={{
            borderBottom: '1px solid #e7e7e7',
            padding: '0px 0px 0px 12px',
          }}
        >
          <div style={{ paddingRight: '15px' }}>
            <Input
              variant="borderless"
              className="topbar__popover-content-tab__search-input"
              placeholder={placeholderMap[name]}
              prefix={<SearchIcon />}
              value={searchValue}
              style={{ paddingLeft: isCountryTab ? '12px' : '0px' }}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearch(e.target.value);
              }}
            />
          </div>
          {isCountryTab ? (
            <Text
              style={{
                color: '#B9B9B9',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '22px',
                textAlign: 'left',
                marginTop: '8px',
                paddingLeft: '12px',
                borderRight: showBorder ? '1px solid #E7E7E7' : 'unset',
              }}
            >
              {countryList[type - 1]?.label}
            </Text>
          ) : null}

          <div
            className="topbar__country-tab-list-container "
            style={countryTabContainerStyles}
          >
            {isLoading ? (
              <div
                style={{
                  display: ' flex',
                  justifyContent: 'center',
                  width: '400px',
                  alignItems: 'center',
                  borderRight: '1px solid #e7e7e7',
                  height: '172px',
                }}
              >
                <Spin />
              </div>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: isCountryTab ? 150 : 172,
                }}
              >
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      width={width}
                      height={height}
                      rowHeight={18}
                      rowRenderer={(props) => {
                        return renderRow(props);
                      }}
                      rowCount={data.length}
                    />
                  )}
                </AutoSizer>
              </div>
            )}

            <div
              className="fade-bottom"
              style={{ height: isCountryTab ? '30.5px' : '31.0px' }}
            />
          </div>
        </div>
        <div
          className="topbar__country-summary-list-container topbar__country-tab-list-container"
          style={{
            padding: isCountryTab
              ? '8.5px 0px 20px 24px'
              : '8.5px 0px 20px 12px',
          }}
        >
          <div className="flex align-center space-between">
            {isCountryTab ? <Heading>Summary</Heading> : <Heading></Heading>}
            <Button
              variant="ghost"
              onClick={() => {
                resetList();
                setFilteredList({});
                onChange({});
              }}
              disabled={!summaryList.length}
              style={{
                color: !summaryList.length ? '#bbbbbb' : '#1a016e',
                fontSize: '10px',
                fontStyle: 'normal',
                textDecoration: 'underline',
                paddingRight: '8px',
              }}
            >
              Remove
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
              overflow: 'auto',
            }}
            className="topbar__country-tab-list-container "
          >
            {summaryList?.map((item) => {
              const [key, value] = item;
              return (
                <>
                  <div
                    className="flex align-center "
                    style={{ height: '18px' }}
                  >
                    <Text key={key} style={{ color: '#000000E0' }}>
                      {value?.attributes?.name || value?.name || value?.street}
                    </Text>
                    <Button
                      variant="ghost"
                      style={{ paddingLeft: '4px' }}
                      onClick={() => {
                        setData((prev) => {
                          return prev?.map((x) => {
                            if (x?.id === value?.id) {
                              return { ...x, selected: false };
                            }
                            return x;
                          });
                        });

                        toggleSelectedOption(key);
                      }}
                    >
                      <CrossIcon />
                    </Button>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    </div>
  );
}

const CountryTab = ({
  countryRegionMap,
  dispatchAction,
  cityList,
  setIsOpen,
  handleSubmit,
  isSubmitting,
  isDirty,
  overlayState,
}) => {
  const [cityLoading, setCityLoading] = useState(false);
  const [streetList, setStreetList] = useState([]);

  const [countryFilterList, setCountryFilterList] = useState({});
  const [cityFilterList, setCityFilterList] = useState({});
  const [streetFilterList, setStreetFilterList] = useState({});

  const { type } = useParams();

  const handleOnCountrySelect = (data) => {
    if (isEmpty(data)) {
      dispatchAction(PlannerActions.UPDATE_CITY_LIST, {
        payload: [],
      });
      setCityFilterList({});
      const payload = {
        selectedCity: {},
      };
      dispatchAction(PlannerActions.UPDATE_GEOFILTER_OVERLAY_STATE, {
        ...payload,
      });

      return;
    }

    const countryIds = Object.keys(data).join(',');
    dispatchAction(PlannerActions.FETCH_CITY_LIST, {
      setLoading: setCityLoading,
      countryIds,
    });
  };

  const handleOnCitySelect = (data) => {
    const normalizeStreets = Object.values(data).reduce((acc, current) => {
      const normalizeStreets = current?.streets?.map((street) => {
        return {
          ...street,
          id: v4(),
          attributes: { lat: street.lat, long: street.lon },
        };
      });
      return [...acc, ...normalizeStreets];
    }, []);

    setStreetList(normalizeStreets);
  };

  return (
    <>
      <div className="flex">
        <TabContent
          name="country"
          initialValue={countryRegionMap[type]}
          onChange={handleOnCountrySelect}
          initialFilterValue={overlayState.countryIds}
          dispatchAction={dispatchAction}
          filteredList={countryFilterList}
          setFilteredList={setCountryFilterList}
          type={type}
        />
        <TabContent
          name="city"
          onChange={handleOnCitySelect}
          initialValue={cityList}
          isLoading={cityLoading}
          initialFilterValue={overlayState.cityIds}
          dispatchAction={dispatchAction}
          filteredList={cityFilterList}
          setFilteredList={setCityFilterList}
        />
        <TabContent
          index={3}
          initialValue={streetList}
          name="street"
          dispatchAction={dispatchAction}
          overlayState={overlayState}
          filteredList={streetFilterList}
          setFilteredList={setStreetFilterList}
        />
      </div>
      <div
        style={{
          display: 'Flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '16px',
          marginBottom: '20px',
        }}
      >
        <div className="topbar__location-tab-buttons">
          <Button
            variant="outline"
            color="secondary"
            onClick={() => {
              setIsOpen(false);
              dispatchAction(PlannerActions.UPDATE_CITY_LIST, {
                payload: [],
              });
              dispatchAction(PlannerActions.UPDATE_GEOFILTER_OVERLAY_STATE, {
                selectedLocation: {},
                selectedCity: {},
                selectedCountries: {},
              });
            }}
            style={{
              fontFamily: "'Inter', sans-serif",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting || isDirty}
            onClick={() => {
              handleSubmit();
            }}
            style={{
              fontFamily: "'Inter', sans-serif",
            }}
          >
            Apply Settings
          </Button>
        </div>

        <Button
          variant="ghost"
          onClick={() => {}}
          disabled={isDirty}
          style={{
            // color: isDirty ? '#BBBBBB' : '#1a016e',
            fontSize: '10px',
            fontStyle: 'normal',
            textDecoration: 'underline',
            padding: '0px',
            position: 'absolute',
            right: '18px',
          }}
        >
          Remove All Settings
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    countryRegionMap: getcountryRegionMapSelector(state),
    cityList: getCityListSelector(state),
    overlayState: getGeoFilterStateSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (type, payload) => {
    dispatch(actionHandler(type, payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryTab);
