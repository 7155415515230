import { Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import CountryTab from './CountryTab';
import { PlannerActions } from '@store/actions';
import { getMapboxInstance } from '@utils/mapboxInstance';
import { getGeoFilterStateSelector } from '@store/selectors';
import { connect } from 'react-redux';
import { getTopBarSearchValue, getNormalizePayload } from '../utils';
import { isEmpty } from 'lodash';
import { TopBarContext } from '..';

const Content = ({ setIsOpen, dispatchAction, geoOverlayState }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setTopBarSearchInput } = useContext(TopBarContext);
  const isDirty =
    !isEmpty(geoOverlayState?.latlang) ||
    !isEmpty(geoOverlayState?.countryIds) ||
    !isEmpty(geoOverlayState?.cityIds);

  const handleSubmit = () => {
    const normalizePayload = getNormalizePayload(geoOverlayState);

    dispatchAction(PlannerActions.APPLY_GEO_FILTER_SETTINGS, {
      setLoading: setIsSubmitting,
      payload: {
        ...normalizePayload,
      },
      onSuccess: (screenData) => {
        const mapInstance = getMapboxInstance();
        const featureCollection = {
          type: 'FeatureCollection',
          features: screenData,
        };
        mapInstance.getSource('screenType').setData(featureCollection);
        setIsOpen?.(false);
        const topBarSearchValue = getTopBarSearchValue(geoOverlayState);
        setTopBarSearchInput(topBarSearchValue);
      },
    });
  };

  const items = [
    {
      key: '1',
      label: 'Countries, Cities, Streets',
      children: (
        <CountryTab
          setIsOpen={setIsOpen}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          dispatchAction={dispatchAction}
          isDirty={!isDirty}
        />
      ),
    },
    {
      key: '3',
      label: 'Bulk CSV Upload',
      children: 'Bulk Upload',
    },
  ];

  return (
    <div className="topbar__popover-content flex-1  ">
      <Tabs defaultActiveKey="1" items={items} centered={true} type="card" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    geoOverlayState: getGeoFilterStateSelector(state),
  };
};

export default connect(mapStateToProps, null)(Content);
