import React, { createContext, useEffect, useState } from 'react';
import './topbar.scss';
import Button from '@components/Button/Button';
import MenuIcon from '../../Images/MenuIcon.svg';
import Logo from '../../Images/logo.svg';
import { Popover, PopoverTrigger, PopperContent } from '@components/Popover';
import { useLocation } from 'react-router-dom';
import Content from './component/Content';
import { actionHandler, PlannerActions } from '@store/actions';
import { connect } from 'react-redux';
import TopBarSearchInput from './component/TopBarSearchInput';
import { getMapboxInstance, resetMapInstance } from '@utils/mapboxInstance';

export const TopBarContext = createContext({});

const Topbar = ({ dispatchAction }) => {
  const location = useLocation();
  const isPlannnerScreen = location?.pathname?.includes('planner-screen');
  const [isOpen, setIsOpen] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [topBarSearchInput, setTopBarSearchInput] = useState('');

  useEffect(() => {
    dispatchAction(PlannerActions.FETCH_LOCATION_LIST, {
      setLoading: setLocationLoading,
    });
    dispatchAction(PlannerActions.FETCH_COUNTRY_LIST);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isPlannnerScreen) {
      let mapInstance = getMapboxInstance();
      setTopBarSearchInput('');
      setIsOpen(false);
      if (mapInstance) {
        resetMapInstance();
      }

      dispatchAction(PlannerActions.UPDATE_CITY_LIST, {
        payload: [],
      });

      dispatchAction(PlannerActions.UPDATE_GEOFILTER_OVERLAY_STATE, {
        selectedLocation: {},
        selectedCity: {},
        selectedCountries: {},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlannnerScreen]);

  const topbarStyles = isPlannnerScreen
    ? {
        padding: '16px 27px 16px 0px',
      }
    : {
        padding: '10.7px 27px 10.7px 0px',
        marginBottom: '40px',
      };

  return (
    <div className="topbar__container" style={topbarStyles}>
      <Button
        variant="ghost"
        style={{ padding: isPlannnerScreen ? '0px 16px' : '0px 19px' }}
      >
        <img src={MenuIcon} width="20px" height="20px" alt="side_menu_button" />
      </Button>

      <img src={Logo} width="94.587px" height="22.34px" alt="verve_logo" />
      {isPlannnerScreen ? (
        <Popover
          isOpen={isOpen}
          onChange={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <PopoverTrigger>
            {(triggerProps) => {
              return (
                <TopBarSearchInput
                  triggerProps={triggerProps}
                  isOpen={isOpen}
                  topBarSearchInput={topBarSearchInput}
                  setTopBarSearchInput={setTopBarSearchInput}
                />
              );
            }}
          </PopoverTrigger>
          <PopperContent
            className="flex w-100 topbar__popover-wrapper"
            popoverPosition={'bottom'}
          >
            <TopBarContext.Provider
              value={{ topBarSearchInput, setTopBarSearchInput }}
            >
              <Content
                setIsOpen={setIsOpen}
                locationLoading={locationLoading}
                dispatchAction={dispatchAction}
              />
            </TopBarContext.Provider>
          </PopperContent>
        </Popover>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (type, payload) => {
    dispatch(actionHandler(type, payload));
  },
});

export default connect(null, mapDispatchToProps)(Topbar);
