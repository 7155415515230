export function convertToDMS(attributes) {
  const { lat, long } = attributes;

  // Convert latitude to DMS format
  const latNum = parseFloat(lat);
  const latDirection = latNum >= 0 ? 'N' : 'S';
  const latDMS = Math.abs(latNum).toFixed(4);

  // Convert longitude to DMS format
  const longNum = parseFloat(long);
  const longDirection = longNum >= 0 ? 'E' : 'W';
  const longDMS = Math.abs(longNum).toFixed(4);

  return `${latDMS}° ${latDirection} ${longDMS}° ${longDirection}`;
}
export function convertToDecimal(dmsString) {
  // Split the input string by the delimiter "|"
  const [coordinates, id] = dmsString.split('|').map((item) => item.trim());

  // Extract latitude and longitude details from the coordinates
  const [latDMS, latDirection, longDMS, longDirection] = coordinates.split(' ');

  // Convert latitude and longitude to decimal format
  const latNum = parseFloat(latDMS) * (latDirection === 'S' ? -1 : 1);
  const longNum = parseFloat(longDMS) * (longDirection === 'W' ? -1 : 1);

  // Return the object in the required structure
  return {
    id: parseInt(id, 10),
    attributes: {
      lat: latNum,
      long: longNum,
    },
  };
}

export const getNormalizePayload = (state) => {
  return {
    latLang:
      Object.keys(state.latlang).map((key) => {
        return [
          String(state.latlang[key]?.attributes?.lat),
          String(state.latlang[key]?.attributes?.long),
        ];
      }) || [],
    countryIds: Object.keys(state.countryIds) || [],
    cityIds: Object.keys(state.cityIds) || [],
  };
};

export const getTopBarSearchValue = (geoOverlayState) => {
  const finalArray = [
    ...Object.values(geoOverlayState.countryIds),
    ...Object.values(geoOverlayState.cityIds),
  ];
  return finalArray
    .map((item) => item?.attributes?.name || item?.name || item?.street)
    .join(', ');
};
